import BasePlugin from '../BasePlugin'

export default class VehicleTracking extends BasePlugin {
  async execute () {
    let lpn = this.context.getModel()['attr_2421_']
    if (!lpn) {
      this.context.$message.error('Не указан Гос. номер')
      return false
    }
    this.context.addMainTab({ name: 'Трекинг машин на карте', componentType: 'IFrame', payload: { url: `http://map.fss-rr.bingosoft-office.ru?lpn=${lpn}` } })
  }
}
